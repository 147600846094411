globalThis["__sentryRewritesTunnelPath__"] = "/telemetry";
globalThis["SENTRY_RELEASE"] = {"id":"next@120+b3edacc"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import {
  BrowserClient,
  defaultStackParser,
  feedbackAsyncIntegration,
  getCurrentScope,
  makeFetchTransport,
} from "@sentry/nextjs";
import manifest from "../../manifest.json";

import { env } from "@tweetdm/env/client";

const client = new BrowserClient({
  debug: false,
  dsn: "https://6155bd75ea233776aac900ab1423f827@o4507528156938240.ingest.us.sentry.io/4507528158707712",
  integrations: [
    feedbackAsyncIntegration({
      colorScheme: "system",
      isEmailRequired: true,
      autoInject: false,
    }),
  ],
  sampleRate: 0.1,
  tracesSampleRate: 0.25,
  enableTracing: false,
  replaysOnErrorSampleRate: 0,
  replaysSessionSampleRate: 0,
  environment: env.NEXT_PUBLIC_STAGE,
  release: `next@${manifest.build}+${manifest.commit}`,
  transport: makeFetchTransport,
  stackParser: defaultStackParser,
});

getCurrentScope().setClient(client);

client.init();
