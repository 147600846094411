import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

export const env = createEnv({
  client: {
    NEXT_PUBLIC_BASE_URL: z.string().url(),
    NEXT_PUBLIC_STAGE: z
      .enum(["development", "staging", "production"])
      .default("development"),
    NEXT_PUBLIC_SYNC_SERVER_URL: z.string().url(),
    NEXT_PUBLIC_REPLICACHE_LICENSE_KEY: z.string(),
    NEXT_PUBLIC_CHROME_EXTENSION_ID: z.string(),
    NEXT_PUBLIC_POSTHOG_KEY: z.string(),
  },
  runtimeEnv: {
    NEXT_PUBLIC_BASE_URL: process.env.NEXT_PUBLIC_BASE_URL,
    NEXT_PUBLIC_STAGE: process.env.NEXT_PUBLIC_STAGE,
    NEXT_PUBLIC_SYNC_SERVER_URL: process.env.NEXT_PUBLIC_SYNC_SERVER_URL,
    NEXT_PUBLIC_REPLICACHE_LICENSE_KEY:
      process.env.NEXT_PUBLIC_REPLICACHE_LICENSE_KEY,
    NEXT_PUBLIC_CHROME_EXTENSION_ID:
      process.env.NEXT_PUBLIC_CHROME_EXTENSION_ID,
    NEXT_PUBLIC_POSTHOG_KEY: process.env.NEXT_PUBLIC_POSTHOG_KEY,
  },
  skipValidation: !!process.env.SKIP_ENV_VALIDATION,
  emptyStringAsUndefined: true,
});
